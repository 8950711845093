
function Item({item}) {
  return (
      <div className="card">

        <div className="face face1">
          <div className="content">
            <i className="fab fa-windows"></i>            
            <h3>{item.title}</h3>
          </div>
        </div>

        <div className="face face2">
          <div className="content">
            <p>{item.description}</p>
          </div>
        </div>
        
    </div>
            
  );
}

export default Item;
