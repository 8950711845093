// import './App.css';
import Item from './Item'
import items from './data.js'

function App() {
  return (
    <div className="container">
      {items.map((item, index) => 
        <Item key={index} item={item}/>
      )}            
    </div>
  );
}

export default App;
